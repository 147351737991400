// accept an event handler as a prop, trigger when the button is clicked
interface Prop {
	text: string
	onClick: () => void,
	bgColor?: string,
	fontSize?: number,
	width?: number,
	height?: number,
}
export const Button = (prop: Prop) => {
	return (
		<button
			className='font-mont-sb rounded-[14px] text-black px-[20px] truncate'
			style={{
				backgroundColor: `${prop.bgColor || '#F6C949'}`,
				width: `${prop.width || 240}px`,
				height: `${prop.height || 68}px`,
				fontSize: `${prop.fontSize || 20}px`}}
			onClick={prop.onClick}>{prop.text}</button>
	)
}
