// import images
import pngLogo from '../assets/images/logo.png'
import pngOS from '../assets/images/link-os.png'
import pngTW from '../assets/images/link-tw.png'
import pngDC from '../assets/images/link-dc.png'

import { useEffect, useState } from 'react'

// import Components
import {Button} from "./Button";
import Wallet from "../utils/wallet";
import {useRecoilState} from "recoil";
import {addressState} from "../store";
import {generateTree} from "../utils";

export const Header = () => {
  const wallet = new Wallet.Wallet()

  const [address, setAddress] = useRecoilState(addressState)
  const [disBtnShow, setDisBtnShow] = useState(false)

  useEffect(() => {
    generateTree()
    wallet.Init()
  }, [])

  // methods
  async function connect() {
    await wallet.getConnect();
  }

  function showDisconnect() {
    setDisBtnShow(true)
  }

  function disconnect() {
    wallet.disconnect()
    setAddress('')
    setDisBtnShow(false)
  }

  // custom doms
  // const navs = ['Home', 'About', 'Tokenomics', 'Milestone', 'Airdrop', 'NFT']
  const navs = [{
    name: 'DOGC',
    url: 'https://dogeclub.wtf'
  }, {
    name: 'MUTANT',
    url: 'https://mutant.dogeclub.wtf'
  }]
  const links = [{
    name: 'OpenSea',
    icon: pngOS,
    url:  'https://opensea.io/collection/dogeclub-dogc'
  }, {
    name: 'Twitter',
    icon: pngTW,
    url: 'https://twitter.com/DogeClub_NFT'
  }, {
    name: 'Discord',
    icon: pngDC,
    url: 'https://discord.gg/cxjtYNcGQn'
  }]
  const navDom =
    <div className="flex ml-auto">
      {
        navs.map((nav, index) => (
          <div key={nav.name} className={`mr-[40px]`}>
            <a href={nav.url} target='_blank'>
              {nav.name}
            </a>
          </div>
        ))
      }
    </div>
  const linkDom =
    <div className="flex">
      {links.map((link) => (
        <div
          key={link.name}
          className='mr-[26px] w-[26px] h-[26px] bg-contain'
          style={{backgroundImage: `url(${link.icon})`}}
        >
          <a
            className='block w-full h-full'
            href={link.url}
            target='_blank'
            rel="noreferrer"></a>
        </div>
      ))}
    </div>

  // button props
  const buttonProps = {
    onClick: connect,
    width: 196,
    height: 50,
    fontSize: 16,
    text: 'Connect Wallet'
  }

  const addressButtonProps = {
    onClick: showDisconnect,
    width: 196,
    height: 50,
    fontSize: 16,
    text: address
  }

  const disButtonProps = {
    onClick: disconnect,
    width: 196,
    height: 50,
    fontSize: 16,
    text: 'Disconnect',
    bgColor: '#ffffff'
  }

  return (
    <div
      className='relative flex w-full items-center px-[146px] py-[10px] font-mont-sb text-white'
    >
      <img src={pngLogo} className='w-[172px]' alt=""/>
      {/*parts of the site*/}
      {navDom}
      {/*links to OS, TW, DC*/}
      {linkDom}
      {address
        ? (<div className='relative'>
          <Button {...addressButtonProps}></Button>
          <div className='absolute left-0 botton-[-50px]'>
            {disBtnShow ? <Button {...disButtonProps}></Button> : ''}
          </div>
        </div>)
        : <Button {...buttonProps}></Button>}
    </div>
  )
}
