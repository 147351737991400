import {s6Bg, s6Card1, s6Card2, s6Card3, s6Card4, s6CardBg} from "../assets/images";
import {ReactElement} from "react";

interface CardProp {
	icon: string,
	title: string,
	content: ReactElement
}

const Card = (prop:CardProp) => {
	return (
		<div
			style={{backgroundImage: `url(${s6CardBg})`}}
			className='h-[520px] w-[358px] bg-bottom bg-contain bg-no-repeat text-[#333333] py-[12px] px-[22px] xl:mb-[20px]'
		>
			<img className='mx-auto w-[126px] mb-[27px]' src={prop.icon} alt=""/>
			<p className='text-[24px] mb-[15px] jfont-mont-sb leading-[28px] text-black'>{prop.title}</p>
			<div className='text-16px leading-[19px] text-left'>
				{prop.content}
			</div>
		</div>
	)
}
export const Section6 = () => {
	const card1Content = <div>
		<p>
			Create Doge MEME cartoon version to
			fill the gap in the cartoon field and
			expand the use cases for MEMEs.
		</p><br/>
		<ul className='list-disc ml-[18px]'>
			<li>Doge cartoon MEMEs ∞</li>
			<li>Doge MEME (Community) ∞</li>
			<li>Doge Card (Art NFT) to enhance the prosperity of the Doge art ecosystem.</li>
		</ul><br/>
		<p>
			Spread DogeClub MEMEs on MEME
			websites such as Giphy, TENOR, GIPHY,
			Pinterest, etc., and get them featured
			in the Twitter GIF library to trend on
			Twitter
		</p>
	</div>
	const card2Content = <div>
		<p>
			Let's create and spread the cartoon
			version inspired by DOGE MEME
			worldwide.
		</p><br/>

		<p>
			Use DogeClub memes to trend $DOGC
			and #DOGE on Twitter.
		</p><br/>

		<p>
			Attract more holders - there are 3.2
			million holders of Dogecoin, let's bring
			in those who love the cartoon version.
		</p><br/>

		<p>
			Together, create a MEME in the
			cryptocurrency market and align it
			with DOGE to reach a wider audience.
		</p><br/>
	</div>
	const card3Content = <div>
		<p>MEME Combat.<br/>
			Fearless against any powerful MEME,
			whether as friend or foe.<br/>
			No one can stop us from trending on
			Twitter, including earning Elon's love.</p>
	</div>
	const card4Content = <div>
		<ul className='list-disc ml-[18px]'>
			<li>Interact with other MEMEs through</li>
			<li>cooperation.</li>
			<li>MEME Tools</li>
			<li>MEME Game</li>
			<li> MEME NFT</li>
			<li>Doge Collectible Card NFT</li>
			<li>CEX listings</li>
			<li>Exchange Listings</li>
			<li>Commercial partnerships</li>
			<li>Charitable donations</li>
		</ul>
	</div>

	const cards = [{
		title: 'Cartoon',
		icon: s6Card1,
		content: card1Content
	}, {
		title: 'Community',
		icon: s6Card2,
		content: card2Content
	}, {
		title: 'Combat',
		icon: s6Card3,
		content: card3Content
	}, {
		title: 'Cooperation',
		icon: s6Card4,
		content: card4Content
	}]
	return (
		<div
			className='flex flex-col items-center relative text-white font-medium py-[157px] bg-cover bg-center text-center'
			style={{backgroundImage: `url(${s6Bg})`}}>
			<p className='font-mont-black leading-[75px] text-title mb-[38px]'>Milestone</p>
			<p className='leading-[29px] text-[24px] mb-[16px]'>A rough sketch of $DOGC path ahead. Let's look forward to more surprises to come.</p>
			<p className='leading-[29px] text-[24px] mb-[48px]'>The C in Doge Club represents
				<span className='font-black'>Cartoon Community Combat Cooperation</span>
			</p>
			<div className='flex w-[1556px] justify-between xl:flex-wrap xl:w-[800px]'>
				{cards.map(item => <Card key={item.title} {...item}></Card>)}
			</div>
		</div>
	)
}
