import {s4Role1, s4Role2} from "../assets/images";
export const Section4 = () => {
	const contractAddress = '0xda8263d8ce3F726233f8b5585bcb86a3120a58B6'
	const uniswapUrl = `https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=${contractAddress}`
	return (
		<div className='relative bg-[#F1F8FB] pt-[141px] pb-[114px] w-full overflow-hidden flex flex-col items-center'>
			<img className='absolute w-[299px] top-[498px] ml-[-605px] left-1/2' src={s4Role1} alt=""/>
			<img className='absolute w-[539px] top-[114px] ml-[200px] left-1/2' src={s4Role2} alt=""/>
			<p className='text-center font-mont-black text-title leading-[78px]'>How To Buy</p>
			<iframe title='uniswap' className='relative w-[574px] h-[432px] mt-[49px] mb-[74px]' src={uniswapUrl}></iframe>
			<p className='font-mont-medium text-center text-[16px] text-[#0D0D0D] leading-[19px] opacity-80'>
				Contract Address：{contractAddress}
			</p>
		</div>
	)
}
