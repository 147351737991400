import {s7Role, s7TW, s7TG, s7DC, logoBlack} from '../assets/images'
export const Section7 = () => {
	const links = [{
		name: 'telegram',
		icon: s7TG,
		url: 'https://t.me/DogeClub_MemeParty'
	}, {
		name: 'twitter',
		icon: s7TW,
		url: 'https://twitter.com/DogeClub_NFT'
	}, {
		name: 'discord',
		icon: s7DC,
		url: 'https://discord.gg/cxjtYNcGQn'
	}]
	return (
		<div className='relative text-[#333] font-mont-medium pt-[207px]'>
			<img src={s7Role} className='absolute w-[487px] left-1/2 top-[154px] ml-[-600px]' alt=""/>
			<div className='w-[1140px] mx-auto pl-[600px] mb-[192px]'>
				<p className='text-title mb-[21px] text-black leading-[78px] font-mont-black'>Statement</p>
				<p className='text-16px leading-[19px] mb-[50px]'>
					$DOGC token has no association with Kabosu or $DOGE. This token
					simply pays tribute to a meme that we all love and recognize.
					We support and love DOGE MEME, and for this reason, we created
					this DOGE cartoon version. This token has no intrinsic value and is
					for entertainment purposes only. Of course, please continue to
					support and love our DogeClub MEME
				</p>
				<div className='flex'>
					{links.map(item => {
						return <div
							key={item.name}
							className='mr-[50px] w-[50px] h-[50px] bg-contain'
							style={{backgroundImage: `url(${item.icon})`}}
						>
							<a
								className='block w-full h-full'
								href={item.url}
								target='_blank'
								rel="noreferrer"></a>
						</div>
					})}
				</div>
			</div>
			<div className='h-[156px] flex items-center px-[147px] mt-auto'>
				<img src={logoBlack} className='w-[172px]' alt=""/>
				<p className='ml-auto font-mont-medium text-[#333333] text-16px'>© 2023 by DogeClub. All rights reserved!</p>
			</div>
		</div>
	)
}
