// import images from 001.jpg to 176.jpg
import jpg1 from './001.jpg'
import jpg2 from './002.jpg'
import jpg3 from './003.jpg'
import jpg4 from './004.jpg'
import jpg5 from './005.jpg'
import jpg6 from './006.jpg'
import jpg7 from './007.jpg'
import jpg8 from './008.jpg'
import jpg9 from './009.jpg'
import jpg10 from './010.jpg'
import jpg11 from './011.jpg'
import jpg12 from './012.jpg'
import jpg13 from './013.jpg'
import jpg14 from './014.jpg'
import jpg15 from './015.jpg'
import jpg16 from './016.jpg'
import jpg17 from './017.jpg'
import jpg18 from './018.jpg'
import jpg19 from './019.jpg'
import jpg20 from './020.jpg'
import jpg21 from './021.jpg'
import jpg22 from './022.jpg'
import jpg23 from './023.jpg'
import jpg24 from './024.jpg'
import jpg25 from './025.jpg'
import jpg26 from './026.jpg'
import jpg27 from './027.jpg'
import jpg28 from './028.jpg'
import jpg29 from './029.jpg'
import jpg30 from './030.jpg'
import jpg31 from './031.jpg'
import jpg32 from './032.jpg'
import jpg33 from './033.jpg'
import jpg34 from './034.jpg'
import jpg35 from './035.jpg'
import jpg36 from './036.jpg'
import jpg37 from './037.jpg'
import jpg38 from './038.jpg'
import jpg39 from './039.jpg'
import jpg40 from './040.jpg'
import jpg41 from './041.jpg'
import jpg42 from './042.jpg'
import jpg43 from './043.jpg'
import jpg44 from './044.jpg'
import jpg45 from './045.jpg'
import jpg46 from './046.jpg'
import jpg47 from './047.jpg'
import jpg48 from './048.jpg'
import jpg49 from './049.jpg'
import jpg50 from './050.jpg'
import jpg51 from './051.jpg'
import jpg52 from './052.jpg'
import jpg53 from './053.jpg'
import jpg54 from './054.jpg'
import jpg55 from './055.jpg'
import jpg56 from './056.jpg'
import jpg57 from './057.jpg'
import jpg58 from './058.jpg'
import jpg59 from './059.jpg'
import jpg60 from './060.jpg'
import jpg61 from './061.jpg'
import jpg62 from './062.jpg'
import jpg63 from './063.jpg'
import jpg64 from './064.jpg'
import jpg65 from './065.jpg'
import jpg66 from './066.jpg'
import jpg67 from './067.jpg'
import jpg68 from './068.jpg'
import jpg69 from './069.jpg'
import jpg70 from './070.jpg'
import jpg71 from './071.jpg'
import jpg72 from './072.jpg'
import jpg73 from './073.jpg'
import jpg74 from './074.jpg'
import jpg75 from './075.jpg'
import jpg76 from './076.jpg'
import jpg77 from './077.jpg'
import jpg78 from './078.jpg'
import jpg79 from './079.jpg'
import jpg80 from './080.jpg'
import jpg81 from './081.jpg'
import jpg82 from './082.jpg'
import jpg83 from './083.jpg'
import jpg84 from './084.jpg'
import jpg85 from './085.jpg'
import jpg86 from './086.jpg'
import jpg87 from './087.jpg'
import jpg88 from './088.jpg'
import jpg89 from './089.jpg'
import jpg90 from './090.jpg'
import jpg91 from './091.jpg'
import jpg92 from './092.jpg'
import jpg93 from './093.jpg'
import jpg94 from './094.jpg'
import jpg95 from './095.jpg'
import jpg96 from './096.jpg'
import jpg97 from './097.jpg'
import jpg98 from './098.jpg'
import jpg99 from './099.jpg'
import jpg100 from './100.jpg'
import jpg101 from './101.jpg'
import jpg102 from './102.jpg'
import jpg103 from './103.jpg'
import jpg104 from './104.jpg'
import jpg105 from './105.jpg'
import jpg106 from './106.jpg'
import jpg107 from './107.jpg'
import jpg108 from './108.jpg'
import jpg109 from './109.jpg'
import jpg110 from './110.jpg'
import jpg111 from './111.jpg'
import jpg112 from './112.jpg'
import jpg113 from './113.jpg'
import jpg114 from './114.jpg'
import jpg115 from './115.jpg'
import jpg116 from './116.jpg'
import jpg117 from './117.jpg'
import jpg118 from './118.jpg'
import jpg119 from './119.jpg'
import jpg120 from './120.jpg'
import jpg121 from './121.jpg'
import jpg122 from './122.jpg'
import jpg123 from './123.jpg'
import jpg124 from './124.jpg'
import jpg125 from './125.jpg'
import jpg126 from './126.jpg'
import jpg127 from './127.jpg'
import jpg128 from './128.jpg'
import jpg129 from './129.jpg'
import jpg130 from './130.jpg'
import jpg131 from './131.jpg'
import jpg132 from './132.jpg'
import jpg133 from './133.jpg'
import jpg134 from './134.jpg'
import jpg135 from './135.jpg'
import jpg136 from './136.jpg'
import jpg137 from './137.jpg'
import jpg138 from './138.jpg'
import jpg139 from './139.jpg'
import jpg140 from './140.jpg'
import jpg141 from './141.jpg'
import jpg142 from './142.jpg'
import jpg143 from './143.jpg'
import jpg144 from './144.jpg'
import jpg145 from './145.jpg'
import jpg146 from './146.jpg'
import jpg147 from './147.jpg'
import jpg148 from './148.jpg'
import jpg149 from './149.jpg'
import jpg150 from './150.jpg'
import jpg151 from './151.jpg'
import jpg152 from './152.jpg'
import jpg153 from './153.jpg'
import jpg154 from './154.jpg'
import jpg155 from './155.jpg'
import jpg156 from './156.jpg'
import jpg157 from './157.jpg'
import jpg158 from './158.jpg'
import jpg159 from './159.jpg'
import jpg160 from './160.jpg'
import jpg161 from './161.jpg'
import jpg162 from './162.jpg'
import jpg163 from './163.jpg'
import jpg164 from './164.jpg'
import jpg165 from './165.jpg'
import jpg166 from './166.jpg'
import jpg167 from './167.jpg'
import jpg168 from './168.jpg'
import jpg169 from './169.jpg'
import jpg170 from './170.jpg'
import jpg171 from './171.jpg'
import jpg172 from './172.jpg'
import jpg173 from './173.jpg'

// export all images in an array
export const swiper1Images = [
  jpg1,  jpg2,  jpg3,  jpg4,   jpg5,  jpg6,
  jpg7,  jpg8,  jpg9,  jpg10,  jpg11, jpg12,
  jpg13, jpg14, jpg15, jpg16,  jpg17, jpg18,
  jpg19, jpg20, jpg21, jpg22,  jpg23, jpg24,
  jpg25, jpg26, jpg27, jpg28,  jpg29, jpg30,
  jpg31, jpg32, jpg33, jpg34,  jpg35, jpg36,
  jpg37, jpg38, jpg39, jpg40,  jpg41, jpg42,
  jpg43, jpg44, jpg45, jpg46,  jpg47, jpg48,
  jpg49, jpg50, jpg51, jpg52,  jpg53, jpg54,
  jpg55, jpg56, jpg57, jpg58,  jpg59, jpg60,
  jpg61, jpg62, jpg63, jpg64,  jpg65, jpg66,
  jpg67, jpg68, jpg69, jpg70,  jpg71, jpg72,
  jpg73, jpg74, jpg75, jpg76,  jpg77, jpg78,
  jpg79, jpg80, jpg81, jpg82,  jpg83, jpg84,
  jpg85, jpg86, jpg87, jpg88,  jpg89, jpg90,
  jpg91, jpg92, jpg93, jpg94,  jpg95, jpg96,
  jpg97, jpg98, jpg99, jpg100, jpg101, jpg102,
  jpg103, jpg104, jpg105,
  jpg106, jpg107, jpg108, jpg109, jpg110,
  jpg111, jpg112, jpg113, jpg114, jpg115,
  jpg116, jpg117, jpg118, jpg119, jpg120,
  jpg121, jpg122, jpg123, jpg124, jpg125,
  jpg126, jpg127, jpg128, jpg129, jpg130,
  jpg131, jpg132, jpg133, jpg134, jpg135,
  jpg136, jpg137, jpg138, jpg139, jpg140,
  jpg141, jpg142, jpg143, jpg144, jpg145,
  jpg146, jpg147, jpg148, jpg149, jpg150,
  jpg151, jpg152, jpg153, jpg154, jpg155,
  jpg156, jpg157, jpg158, jpg159, jpg160,
  jpg161, jpg162, jpg163, jpg164, jpg165,
  jpg166, jpg167, jpg168, jpg169, jpg170,
  jpg171, jpg172, jpg173
]
