import {s3Bg} from "../assets/images";
import React from "react";
export const Section3 = () => {
	const content = [
		'90.1% of the tokens were sent to the liquidity pool, LP tokens were burnt,<br/>and contract is renounced',
		'6% of the tokens were allocated to thousands of Doge Club NFT holders <br/>who have been building this community for around a year. The unclaimed<br/>portion will be put into a multi-sig wallet.',
		'The remaining 3.9% of the supply is being held in a multi-sig wallet only <br/>to be used as tokens for future centralized exchange listings, bridges,<br/>and liquidity pools.'
	]

	return (
		<div
			className='relative text-white font-medium pt-[112px] pb-[234px] bg-gradient-to-b from-[#103A65] to-[#63B4FF]'>
			<img className='absolute w-[567px] top-[231px] ml-[75px] left-1/2' src={s3Bg} alt=""/>
			<p className='text-title font-mont-black text-center mb-[163px] leading-[78px]'>Tokenomics</p>
			<div className='w-[1140px] mx-auto'>
				<p className='font-mont-black text-[28px] leading-[34px] mb-[46px]'>Token Supply: 200,511,020,000,000</p>
				{content.map((item, index) => (
					<p key={index} className='font-mont-medium opacity-80 text-[16px] leading-[19px] mb-[40px]' dangerouslySetInnerHTML={{__html: item}}></p>
				))}
			</div>
		</div>
	)
}

