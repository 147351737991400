import {s2Bg, s2Doge, pngArrow} from "../assets/images";
import {Button} from "./Button";
import React, {useCallback} from "react";
import {jumpToBuy} from "../utils";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from "swiper";
import "swiper/css/navigation";
import {swiper1Images} from "../assets/swiper1";


export const Section2 = () => {
	const btnProps = {
		text: 'Buy Now',
		onClick: jumpToBuy
	}
	const navigationPrevRef = React.useRef(null)
	const navigationNextRef = React.useRef(null)
	return (
		<div
			className='relative text-[#0D0D0D] font-medium pt-[149px] pb-[72px] flex flex-col items-center'>
			<img className='absolute w-[563px] top-[124px] ml-[-595px] left-1/2' src={s2Bg} alt=""/>
			<div className='w-[1140px] mx-auto pl-[600px] mb-[129px]'>
				<p className='font-black text-title leading-[78px] mb-[20px] text-black'>About</p>
				{/*<p className='text-16px mb-[30px] leading-[19px] opacity-80'>*/}
				<p className='text-16px mb-[98px] leading-[19px] opacity-80'>
					We are the DOGE MEME Club, creating hundreds of Doge cartoon version MEME and thousands of Doge MEMEs over the past year. Join us as we continue to create and spread them worldwide.<br/>
					Our MEME Coin is born from DOGE MEME and features original Doge cartoon version. Join us in creating and nurturing this character, as it competes and collaborates with other powerful MEMEs.
				</p>
				<Button {...btnProps}></Button>

			</div>
			<div className='flex items-center mb-[50px]'>
				<img className='w-[38px]' src={s2Doge} alt=""/>
				<p className='mx-[11px] text-[20px] font-mont-sb text-[#333333]'>I bet you didn't have the patience to read all these</p>
				<img className='w-[38px]' src={s2Doge} alt=""/>
			</div>
			<div className='w-[1140px] relative'>
				<div
					style={{backgroundImage: `url(${pngArrow})`}}
					className='w-[60px] h-[60px] absolute top-[70px] left-[-100px] bg-contain cursor-pointer'
					ref={navigationPrevRef} />
				<div
					style={{backgroundImage: `url(${pngArrow})`}}
					className='w-[60px] h-[60px] absolute top-[70px] right-[-100px] bg-contain cursor-pointer rotate-180'
					ref={navigationNextRef} />
				<Swiper
					spaceBetween={20}
					slidesPerView='auto'
					modules={[Navigation]}
					navigation={{
						prevEl: navigationPrevRef.current,
						nextEl: navigationNextRef.current,
					}}
					onInit={(swiper) => {
						console.log(swiper, 'init')
						// @ts-ignore
						swiper.params.navigation.prevEl = navigationPrevRef.current;
						// @ts-ignore
						swiper.params.navigation.nextEl = navigationNextRef.current;
						swiper.navigation.init();
						swiper.navigation.update();
					}}
					// navigation={true}
				>
					{swiper1Images.map((item, index) => {
						return <SwiperSlide key={index}>
							<img className='h-[200px] rounded-[16px]' loading="lazy" src={item} key={index} alt=""/>
						</SwiperSlide>
					})}
				</Swiper>
			</div>
		</div>
	)
}
