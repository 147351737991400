import React from 'react';
import {RecoilRoot} from "recoil";
import RecoilNexus from 'recoil-nexus'
import {Section1} from './components/Section1';
import {Section2} from './components/Section2';
import {Section3} from './components/Section3';
import {Section4} from './components/Section4';
import {Section5} from './components/Section5';
import {Section6} from './components/Section6';
import {Section7} from './components/Section7';
import './App.css';

function App() {
  return (
    <RecoilRoot>
      <RecoilNexus />
      <div className="main w-full min-w-[1140px] overflow-hidden">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
      </div>
    </RecoilRoot>
  );
}

export default App;
